import Button from '../Button';
import Link from 'next/link';
import logoMirage from '@/images/logos/mirage.svg'
import logoStatamic from '@/images/logos/statamic.svg'
import logoStaticKit from '@/images/logos/statickit.svg'
import logoTransistor from '@/images/logos/transistor.svg'
import Image from 'next/image';
import Particles from '../Particles';
import { useEffect } from 'react';

const Header = ({ handleRegister }) => {
  return <header className="relative">
    <Particles />
    <div className={`max-w-4xl mx-auto mt-8 py-16 md:py-28 px-4 sm:px-6 lg:px-8`}>
      <h2 className={`font-sans font-bold text-4xl md:text-5xl lg:text-6xl text-center leading-snug text-gray-1000`}>
        More transparency,{' '}<br></br>happier teams</h2>
      <div className={`max-w-xl mx-auto`}>
        <h1 className={`m-4 md:my-10 text-gray-500 text-center text-xl md:text-3xl`}>
          A framework for Sprint Retrospectives{' '}<br></br>that encourages growth.{' '}🌱
        </h1>
      </div>
      <div className={`flex flex-col sm:flex-row justify-center items-center w-full mx-auto`}>
        <Link href="/retro">
          <Button isPrimary>Start a Retro</Button>
        </Link>
        <span className={`m-2 md:m-4`}>or</span>
        <div className="bg-white rounded">
          <Button isSecondary>Why should all teams do Retros?</Button>
        </div>
      </div>
    </div>
    {/* <div className={`flex justify-center w-full h-20`}> */}
    {/* <div className={`mt-4 w-full`}>
        <p className={`font-mono uppercase text-center font-medium text-sm text-gray-600`}>These folks get it</p>
        <div className={`flex items-center justify-center mx-auto flex-wrap`}>
          <Aws className={`m-12 mb-8`} width={120} />
          <Netlify className={`m-12`} width={140} />
          <Nike className={`m-12`} width={140} />
          <Figma className={`m-12`} width={140} />
        </div>
      </div> */}
    {/* </div> */}
    <div className="mt-16 text-center lg:mt-16 pb-20">
      <p className="text-base text-gray-900">
        Trusted by these companies
      </p>
      <ul
        role="list"
        className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
      >
        {[
          [
            { name: 'Transistor', logo: logoTransistor },
            { name: 'StaticKit', logo: logoStaticKit },
          ],
          [
            { name: 'Mirage', logo: logoMirage },
            { name: 'Statamic', logo: logoStatamic },
          ],
        ].map((group, groupIndex) => (
          <li key={groupIndex}>
            <ul role="list" className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0" >
              {group.map((company) => (
                <li key={company.name} className="flex">
                  <company.logo />
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  </header>
};

export default Header;
